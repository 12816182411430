import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { action, observable } from 'mobx'
import { TopMenu, MenuRow, NavMenu, NavItem, ReCyCleTheme } from 're-cy-cle'
import { Button, Modal, Icon, Popup } from 'semantic-ui-react'
import { IconButton } from 'spider/semantic-ui/Button'
import { Route, withRouter } from 'react-router-dom'
import ImgLogo from 'image/logo.svg'
import { SmallAvatar } from 'component/UserAvatar'
import IntegrationMonitor, { STATUS_COLORS } from 'component/IntegrationMonitor'
import { BUILD_INFO, TIME_FORMAT, PRINT_URL_PARAMS, IS_DEBUG, IS_STAGE } from 'helpers'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
import { getPrinters, getPrinterType, print } from 'helpers/print'
import { theme } from 'styles'
import { Link, NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import RightDivider from 'spider/component/RightDivider'
import { User } from 'store/User'
import { Operator } from 'store/Operator'
import { GlobalValue } from 'store/GlobalValue'
import { map } from 'lodash';
import { IntegrationStore } from 'store/Integration'
import moment from 'moment'
import subscribe from 'decorator/subscribe'

// Copy paste from re-cy-cle just so I could remove the padding...
const Logo = styled(({ children, ...props }) => <NavLink to="/" {...props}>{children}</NavLink>)`
  display: flex;
  align-items: center;
  margin: 0 10px;
  font-size: 32px;
  font-weight: 300;
  text-decoration: none;
  color: inherit;

  svg,
  img {
    max-width: 100%;
  }
`

// Copy paste from re-cy-cle so that it could be a div
export const NavDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin: 0 10px;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  position: relative;
  &.active {
    &:before,
    &:after {
      border-width: 8px;
    }
  }
  &:after {
    position: absolute;
    left: 50%;
    bottom: -1px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-bottom-color: #fff;
    border-top: 0;
    transition: 175ms all ease;
  }
  &:before {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    content: '';
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-bottom-color: ${(props) => props.theme.primaryColor};
    border-top: 0;
    transition: 175ms all ease;
  }
`

const ITEM_HEIGHT = '2.5em'

const UserDropdown = styled.div`
  position: relative;
  z-index: 200;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  overflow: hidden;
  background-color: #fff;
  border-bottom-left-radius: 0.33em;
  border-bottom-right-radius: 0.33em;
  ${({ open }) =>
    open
      ? css`
          height: ${({ children }) => `calc(50px + ${ITEM_HEIGHT} * ${children.filter((child) => child).length - 1})`};
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
        `
      : css`
          height: 50px;
        `}
  transition: height 300ms ease, box-shadow 300ms ease;
`

const UserDropdownHeader = styled.div`
  flex: 0 0 auto;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  ${({ operator }) => `
        cursor: pointer;
    `};
`

const userDropdownItemCss = css`
  padding: 0 10px;
  flex: 0 0 auto;
  height: ${ITEM_HEIGHT};
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.75);

  position: relative;
  > i.icon,
  > i.icons {
    position: absolute;
    line-height: 1;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
`

const hoverableUserDropdownItemCss = css`
  ${userDropdownItemCss}
  &:hover {
    background-color: #eee;
    color: rgba(0, 0, 0, 0.87);
  }
  transition: color 300ms ease, background-color 300ms ease;
`

const UserDropdownMessage = styled.div`
  font-style: italic;
  background-color: ${theme.tintedPrimaryColor};
  ${userDropdownItemCss}
`

const UserDropdownLink = styled(Link)`
  color: inherit;
  ${hoverableUserDropdownItemCss}
`

const UserDropdownButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  ${hoverableUserDropdownItemCss}
`

const ClockedInIcon = styled(Icon)`
  margin: 0 0 0 0.5rem !important;
  line-height: 1;
  align-self: center;
`

const Workstation = styled.span`
  font-size: 18px;
`

const MyLogo = () => (
  <Logo>
    <img src={ImgLogo} alt="TRACY" height="35" />
  </Logo>
)

@withRouter
@observer
@subscribe
export default class AppHeader extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    showSecondMenu: PropTypes.bool.isRequired,
  }

  @observable debug = false
  @observable loginUrlPrintGlobalValue
  @observable printerModel
  @observable integrationStore = new IntegrationStore({
    relations: [
      'exactIntegration',
      'unit4Integration',
      // 'navisionIntegration',
    ],
    params: {
      include_annotations: '*,last_performed_at,type',
      order_by: '-last_performed_at',
    }
  })

  toggleDebug = () => {
    this.debug = !this.debug

    if (this.debug) {
      localStorage.setItem('debug', true)
    } else {
      localStorage.removeItem('debug')
    }
  }

  constructor(...args) {
    super(...args)
    this.debug = !!localStorage.getItem('debug')
    this.onLogout = this.onLogout.bind(this)
    this.onClick = this.onClick.bind(this)
    this.loginUrlPrintGlobalValue = new GlobalValue({ key: 'login_url_print' })
    this.printerModel = new GlobalValue({ key: 'printer_model' })
  }

  @action onClick(e) {
    let target = e.target
    let whileBreak = false

    // While statement to traverse up from the clicked element to the top of the DOM
    // to check whether to open/keep open the dropdowns for user and integrations
    while (target) {
      // If an interaction with a modal happens, don't close any of the menus
      if (target.classList.contains('modal') || target.classList.contains('dimmer')) {
        whileBreak = true
        break
      }

      switch (target.id) {
        case 'user-dropdown-header':
          this.userDropdownClicked = true
          whileBreak = true
          break

        case 'integrations-dropdown-header':
          this.integrationsDropdownClicked = true
          whileBreak = true
          break

        default:
          break
      }

      target = target.parentElement
    }

    // If the click was outside any of the dropdowns, close them
    if (!whileBreak) {
      this.userDropdownClicked = false
      this.integrationsDropdownClicked = false
    }
  }

  componentDidMount() {
    const { store } = this.props
    window.addEventListener('click', this.onClick)
    //fetch integration only when it is a user (not operator or workstation)
    //and also has a group defined
    if (store.currentUser instanceof User && !store.isWorkStation && !store.isOperator && store.currentUser.groupNames.length > 0) {
      this.integrationStore.fetch().then(() => {
        // eslint-disable-next-line
        for (const integration of this.integrationStore.filter(integration => integration.type === 'exact')) {
          // Check for all Exact integrations whether their own reset has passed
          const now = new Date()
          const reset = new Date(integration.exactIntegration.apiRateLimitReset * 1000)

          if (now > reset) {
            integration.exactIntegration.apiRateLimitRemaining = integration.exactIntegration.apiRateLimitLimit
            integration.save()
          }

          // If this integration is of type exact, set up a subscription to listen for
          // sync service status updates
          if (integration.type === 'exact') {
            this.subscribe(
              { type: 'exact_sync_service_status_update', integration_id: integration.id },
              action(() => {
                integration.fetch()
              }
            ))
          }
        }
      })
    }

    if (!store.isWorkStation && !store.isOperator) {
      this.subscribe(
        { type: 'integration_last_performance_update', integration_id: '*', sync_request: '*' },
        action(() => this.integrationStore.fetch())
      )
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClick)
  }

  hasPermission = (perms) => {
    return this.props.store.currentUser.hasPermission(perms)
  }

  /**
   * Provides a list of all links to the pages of the administrative steps
   *
   */
  renderAdministrativeWorkStations() {
    return (
      <>
        {
          map(this.props.store.administrativeWorkstations, (name, id) => {
            return (
              <NavItem
                title={name}
                to={`/operations/perform/${id}/overview`}
                activePath={`/operations/perform/${id}/overview`}
                data-test-nav-work-station={id}
              />
            )

          })
        }

      </>
    )
  }

  renderWorkStationProduction = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.production.productionRequests')}
          to="/operations/production-request/overview"
          activePath="/operations/production-request/"
        />
      </NavMenu>
    )
  }

  renderOperations = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.operations.progress')}
          to="/operations/production-request/overview"
          activePath="/operations/production-request/"
        />
        <NavItem title={t('nav.operations.batches')} to="/operations/batch/overview" activePath="/operations/batch/" />
        <NavItem title={t('nav.operations.orderHistory')} to="/operations/order-history/overview" activePath="/operations/order-history/" />
        {this.renderAdministrativeWorkStations()}
      </NavMenu>
    )
  }

  renderSales = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.sales.salesOrders')}
          to="/demand/sales-order/overview"
          activePath="/demand/sales-order/"
        />
      </NavMenu>
    )
  }

  renderPlanning = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.planning.planner')}
          to="/planning/production-request/planner"
        />
        {isFeatureFlagEnabled('capacity') && (
          <NavItem
            title={t('nav.planning.capacity')}
            to="/planning/capacity/overview"
          />
        )}
        <NavItem
          title={t('nav.planning.productionRequests')}
          to="/planning/production-request/overview"
        />
        {isFeatureFlagEnabled('nesting') && (
          <NavItem
            title={t('nav.planning.nest')}
            to="/planning/nest/overview"
          />
        )}

        <NavItem
          title="Allocations"
          to="/planning/resource-allocations/"
          activePath="planning/resource-allocations/"
        />
      </NavMenu>
    )
  }

  renderWarehouse = () => {
    return (
      <NavMenu>
        <NavItem title={t('nav.warehouse.stock')} to="/warehouse/stock" />
        <NavItem title={t('nav.warehouse.inbound')} to="/warehouse/inbound" />
        <NavItem title={t('nav.warehouse.warehouseTransfer')} to="/warehouse/warehouse-transfer" />
      </NavMenu>
    )
  }

  renderPersonel = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.personnel.operators')}
          to="/personnel/operator/overview"
          activePath="/personnel/operator/"
        />
        <NavItem title={t('nav.personnel.users')} to="/personnel/user/overview" activePath="/personnel/user/" />
        <NavItem
          title={t('nav.personnel.leaveSlots')}
          to="/personnel/leave-slot/overview"
          activePath="/personnel/leave-slot/"
        />
        <NavItem
          title={t('nav.personnel.leaveCalendars')}
          to="/personnel/leave-calendar/overview"
          activePath="/personnel/leave-calendar/"
        />
        <NavItem
          title={t('nav.assets.capabilities')}
          to="/personnel/capability/overview"
          activePath="/personnel/capability/"
        />
      </NavMenu>
    )
  }

  renderPlantation = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.plantation.plantationLines')}
          to="/plantation/plantation-line/overview"
          activePath="/plantation/plantation-line/"
        />
        <NavItem
          title={t('nav.plantation.scripts')}
          to="/plantation/script/overview"
          activePath="/plantation/script/"
        />
        <NavItem
          title={t('nav.plantation.units')}
          to="/plantation/unit/overview"
          activePath="/plantation/unit/"
        />
        <NavItem
          title={t('nav.plantation.plc')}
          to="/plantation/plc/overview"
          activePath="/plantation/plc/"
        />
      </NavMenu>
    )
  }

  renderAssets = () => {
    const { store } = this.props

    return (
      <NavMenu>
        <NavItem
          title={t('nav.assets.articleTypes')}
          to="/assets/article-type/overview"
          activePath="/assets/article-type/"
        />
        <NavItem
          title={t('nav.assets.classifications')}
          to="/assets/classification/overview"
          activePath="/assets/classification/"
        />
        <NavItem
          title={t('nav.assets.processes')}
          to="/assets/process/overview"
          activePath="/assets/process/"
        />
        <NavItem
          title={t('nav.assets.productionLines')}
          to="/assets/production-line/overview"
          activePath="/assets/production-line/"
        />
        {store.machineEnabled && (
          <NavItem
            title={t('nav.assets.dataSources')}
            to="/assets/data-source/overview"
            activePath="/assets/data-source/"
          />
        )}
        <NavItem
          title={t('nav.assets.loadCarriers')}
          to="/assets/load-carrier/overview"
          activePath="/assets/load-carrier/"
        />
        <NavItem
          title={t('nav.assets.projects')}
          to="/assets/projects/overview"
          activePath="/assets/projects/"
        />
        <NavItem
          title={t('nav.assets.factories')}
          to="/assets/factory/overview"
          activePath="/assets/factory/"
        />
        <NavItem
          title={t('nav.warehouse.warehouses')}
          to="/assets/warehouse"
        />
        <NavItem
          title={t('nav.assets.integrations')}
          to="/assets/integration/overview"
          activePath="/assets/integration/"
        />
        <NavItem
          title={t('nav.assets.syncrequest')}
          to="/assets/sync-request/overview"
          activePath="/assets/sync-request/"
        />
        <NavItem
          title={t('nav.assets.globalValues')}
          to="/assets/global-value/overview"
          activePath="/assets/global-value/"
        />
        <NavItem
          title={t('nav.assets.metafields')}
          to="/assets/metafield/overview"
          activePath="/assets/metafield/"
        />
        {isFeatureFlagEnabled('nesting') && (
          <NavItem
            title={t('nav.assets.nests')}
            to="/assets/nest-type/overview"
            activePath="/assets/nest/"
          />
        )}
        <NavItem
          title={t('nav.operations.workStationSessions')}
          to="/assets/work-station-session/overview"
          activePath="/assets/work-station-session/"
        />
      </NavMenu>
    )
  }



  @observable logoutClockOut = null

  async onLogout() {
    const { store, history } = this.props

    const user = !store.currentOperator.isNew ? store.currentOperator : store.currentUser

    if (user.clockInTime) {
      let clockOut
      try {
        clockOut = await new Promise((resolve, reject) => (this.logoutClockOut = { resolve, reject }))
      } finally {
        this.logoutClockOut = null
      }

      if (clockOut) {
        await store.clockOut()
      }
    }

    await store.performLogout()
    history.push('/')
  }

  @observable userDropdownHover = false
  @observable userDropdownClicked = false
  @observable integrationsDropdownClicked = false

  renderAccountMenu = () => {
    const { store } = this.props
    const { version, branch } = BUILD_INFO

    const user = !store.currentOperator.isNew ? store.currentOperator : store.currentUser

    let res = (
      <UserDropdownHeader id="user-dropdown-header" operator={user instanceof Operator}>
        <SmallAvatar user={user} />
        <span data-test-current-user style={{ paddingLeft: '0.5rem' }}>
          {user.fullName} ({branch && branch !== 'production' && branch + ' '}
          {version})
        </span>
        {user instanceof User ? (
          <ClockedInIcon
            data-test-clocked-in={user.clockInTime !== null}
            color={user.clockInTime ? 'green' : 'red'}
            name="clock"
          />
        ) : (
          <ClockedInIcon data-test-logout-icon name="sign-out" />
        )}
      </UserDropdownHeader>
    )

    if (user instanceof User) {
      res = (
        <UserDropdown
          open={this.userDropdownHover || this.userDropdownClicked}
          onMouseEnter={() => (this.userDropdownHover = true)}
          onMouseLeave={() => (this.userDropdownHover = false)}
        >
          {res}
          <UserDropdownMessage data-test-clock-in-time>
            {user.clockInTime
              ? t('nav.account.clockInTime', { clockInTime: user.clockInTime.format(TIME_FORMAT) })
              : t('nav.account.notClockedIn')}
          </UserDropdownMessage>
          {user.clockInTime ? (
            <UserDropdownButton data-test-clock-out-button onClick={store.clockOut}>
              <Icon.Group>
                <Icon name="clock" />
                <Icon corner color="red" name="stop" />
              </Icon.Group>
              {t('nav.account.clockOut')}
            </UserDropdownButton>
          ) : (
            <UserDropdownButton data-test-clock-in-button onClick={store.clockIn}>
              <Icon.Group>
                <Icon name="clock" />
                <Icon corner color="green" name="play" />
              </Icon.Group>
              {t('nav.account.clockIn')}
            </UserDropdownButton>
          )}
          <UserDropdownLink data-test-profile-button to="/profile">
            <Icon name="user" />
            {t('nav.account.profile')}
          </UserDropdownLink>
          <UserDropdownLink data-test-changelog-button to="/changelog">
            <Icon name="list" />
            {t('nav.account.changelog')}
          </UserDropdownLink>
          <UserDropdownButton data-test-logout-button onClick={this.onLogout}>
            <Icon name="sign-out" />
            {t('nav.account.logout')}
          </UserDropdownButton>
        </UserDropdown>
      )
    } else {
      res = (
        <Modal closeIcon size="tiny" trigger={res}>
          <Modal.Header>{t('operator.logoutModal.title')}</Modal.Header>
          <Modal.Content>{t('operator.logoutModal.content')}</Modal.Content>
          <Modal.Actions>
            <Button
              primary
              data-test-logout-button
              icon="sign-out"
              labelPosition="left"
              content={t('operator.logoutModal.logoutButton')}
              onClick={() => this.props.store.performLogout().then(() => this.props.history.push('/operator/login'))}
            />
          </Modal.Actions>
        </Modal>
      )
    }

    let env_ = ''
    if (IS_DEBUG) {
      env_ = 'DEVELOPMENT'
    } else if (IS_STAGE) {
      env_ = 'STAGING'
    }

    return (
      <>
        <UserDropdownHeader>
          <b>{env_}</b>
        </UserDropdownHeader>
        {res}
        {this.logoutClockOut && (
          <Modal open closeIcon onClose={() => this.logoutClockOut.reject()}>
            <Modal.Header>{t('nav.account.clockOutModal.title')}</Modal.Header>
            <Modal.Content>{t('nav.account.clockOutModal.message')}</Modal.Content>
            <Modal.Actions>
              <RightDivider />
              <Button
                compact
                color="green"
                icon="check"
                labelPosition="left"
                content={t('form.yes')}
                onClick={() => this.logoutClockOut.resolve(true)}
              />
              <Button
                compact
                color="red"
                icon="delete"
                labelPosition="left"
                content={t('form.no')}
                onClick={() => this.logoutClockOut.resolve(false)}
              />
            </Modal.Actions>
          </Modal>
        )}
      </>
    )
  }

  renderIntegrationsMenu() {
    if (this.integrationStore.length === 0) {
      return null
    }

    // New Integrations menu dropdown styling
    const IntegrationsDropdown = styled.div`
      position: relative;
      z-index: 201;
      display: flex;
      flex-direction: column;
      background-color: #fff;
    `

    const MenuNavItem = styled.div`
      display: flex;
      flex: 0 0 auto;
      height: 50px;
      align-items: center;
      justify-content: center;
      padding: 0 10px;

      &:hover {
        cursor: pointer;
      }
    `;

    const IntegrationsDropdownCard = styled.div`
      position: absolute;
      left: 50%;
      top: calc(100% - 8px);
      transform: translateX(-50%);
      flex-direction: column;
      justify-content: space-between;
      width: 400px;
      height: 600px;
      background-color: #fff;
      border-radius: 4px;
      ${({ open }) =>
        open
          ? css`
              display: flex;
              box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.25);
            `
          : css`
              display: none;
            `}
      .card-header-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 24px 24px 1.5rem;

        & > * { margin: 0; }
      }
    `

    const IntegrationsDropdownList = styled.div`
      padding: 0 24px;
      overflow: auto;
    `

    const IntegrationsDropdownItem = styled.div`
      padding: 16px 0 14px;
      border-bottom: 1px solid #ddd;

      &:first-child {
        padding-top: 0;
      }
    `

    // The element below was originally a table, but this was difficult to make
    // work with the existing integration tests. This implementation attempts to
    // mimic the table's design using CSS grid. If a new column ever needs to be
    // added, some changes below are necessary.
    const IntegrationsStatusList = styled.div`
      display: grid;
      grid-template-columns: 1fr 1fr;

      .th {
        padding: 8px 0;
        font-weight: bold;
        background-color: #eee;
      }

      & > * {
        padding: 5px 0;
      }

      & > div:nth-child(2n - 1) {
        padding-left: 24px;
      }

      & > div:nth-child(2n) {
        padding-right: 24px;
      }

      & > div:nth-child(3),
      & > div:nth-child(4) {
        padding-top: 10px;
      }

      & > div:nth-last-child(1),
      & > div:nth-last-child(2) {
        padding-bottom: 10px;
      }
    `

    const IntegrationsBubble = styled.div`
      position: absolute;
      right: 6px;
      top: 8px;
      width: 12px;
      height: 12px;
      border-radius: 100%;

      ${({ status }) => status && `
        background-color: ${STATUS_COLORS[status]};
      `}
    `

    let integrationsActivity = []
    let integrationsHTML = []
    let integrationsStatusHTML = []
    let integrationStatusColor = 'green'

    // Loop over all ERP integrations
    const erpIntegrations = ['exact', 'unit4']

    // eslint-disable-next-line
    for (const integration of this.integrationStore.filter(integration => erpIntegrations.includes(integration.type))) {
      const activeIntegration = integration.type === 'exact' ? integration.exactIntegration : integration.unit4Integration

      if (integration.active && !integration.serviceActive) {
        integrationStatusColor = 'red'
      }

      if (integration.type === 'exact') {
        if (integration.exactIntegration.apiRateLimitRemaining === 0 || integration.serviceActive === false) {
          integrationStatusColor = 'red'
        } else if (
          integration.exactIntegration.apiRateLimitRemaining <= integration.exactIntegration.apiRateLimitLimit * 0.25 &&
          integration.exactIntegration.apiRateLimitRemaining > 0 &&
          integrationStatusColor !== 'red'
        ) {
          integrationStatusColor = 'orange'
        }
      }

      integrationsActivity.push(activeIntegration.active)

      // Create HTML for integration in popup
      integrationsHTML.push(
        <IntegrationsDropdownItem data-test-integrations-monitor-integration-type={integration.type}>
          <IntegrationMonitor integration={integration}
            to={`/assets/integration/${integration.id}/perform-sync/overview`}
            activePath="/personnel/" />
        </IntegrationsDropdownItem>
      )

      // Create HTML table element for _integration activity status_ at the bottom of the popup
      integrationsStatusHTML.push(
        <div className="td" style={{ 'textTransform': 'capitalize' }}>
          <Popup style={{ left: '-12px' }}
            trigger={
              <Icon
                name='dot circle'
                color={activeIntegration.active ? 'green' : 'red'}
                style={{ marginRight: '8px' }}
              />}
            content={`${t('integrationsMonitor.integrationLabel')} ${activeIntegration.active ? t('integrationsMonitor.status.active') : t('integrationsMonitor.status.inactive')}`}
          />
          <span>{integration.type}</span>
        </div>
      )

      // Create HTML table element for _sync service activity status_ at the bottom of the popup
      integrationsStatusHTML.push(
        <div className="td" data-test-integrations-monitor-service-active-id={integration.id}>
          <Popup style={{ left: '-12px' }}
            trigger={
              <Icon
                name='dot circle'
                color={integration.serviceActive ? 'green' : 'red'}
                style={{ marginRight: '8px' }}
              />}
            content={t(`integrationsMonitor.status.activityTimestamp.${integration.serviceActive ? 'active' : 'inactive'}`, { timestamp: moment(integration.serviceActiveTimestamp).format('DD-MM-YYYY HH:mm') })}
          />
          <span style={{ textTransform: 'capitalize' }}>{t(`integrationsMonitor.status.${integration.serviceActive ? 'active' : 'inactive'}`)}</span>
        </div>
      )
    }

    // Calculate status
    if (integrationsActivity.includes(false)) {
      integrationStatusColor = 'red'
    }

    return (
      <>
        <IntegrationsDropdown id="integrations-dropdown-header">
          <MenuNavItem id="integrations-dropdown-button">
            <Icon size="large" name="link" style={{ 'marginRight': 0 }}></Icon>
            <IntegrationsBubble data-test-integrations-monitor-bubble status={integrationStatusColor}></IntegrationsBubble>
          </MenuNavItem>
          <IntegrationsDropdownCard open={this.integrationsDropdownClicked}>
            <div className="integrations-dropdown-top-wrapper">
              <div className="card-header">
                <div className="card-header-title">
                  <h3>{t('integrationsMonitor.title')}</h3>
                  <a href="/assets/integration/overview" className="link">{t('integrationsMonitor.viewAll')}</a>
                </div>
              </div>
              <IntegrationsDropdownList>
                {integrationsHTML}
              </IntegrationsDropdownList>
            </div>
            <IntegrationsStatusList>
              <div className="th">{t('integrationsMonitor.integrationLabel')}</div>
              <div className="th">{t('integrationsMonitor.syncServiceStatus.label')}</div>
              {integrationsStatusHTML}
            </IntegrationsStatusList>
          </IntegrationsDropdownCard>
        </IntegrationsDropdown>
      </>
    )
  }

  render() {
    const { store: { currentUser, workStationCode, workStationName }, showSecondMenu } = this.props


    if (this.props.store.isWorkStation) {
      return (
        <ReCyCleTheme theme={{ ...theme, primaryColor: theme.menuColor }}>
          <TopMenu>
            <MenuRow>
              <MyLogo />
              <NavDiv><Workstation>{workStationName} - ({workStationCode})</Workstation></NavDiv>
              <NavDiv>
                <IconButton
                  data-test-print-login-url
                  name="print"
                  size="large"
                  onClick={async () => {
                    await Promise.all([
                      this.loginUrlPrintGlobalValue.fetch(),
                      this.printerModel.fetch(),
                    ])
                    let instructions = this.loginUrlPrintGlobalValue.value

                    const { slug, app_url } = PRINT_URL_PARAMS
                    instructions = instructions.replace(/\{\{slug\}\}/g, slug)
                    instructions = instructions.replace(/\{\{app_url\}\}/g, app_url)

                    const printer = (await getPrinters()).find((printer) => getPrinterType(printer) === this.printerModel.value)
                    if (printer) {
                      await print(printer, instructions)
                    }
                  }}
                />
              </NavDiv>
              <NavMenu></NavMenu>
              <Modal
                closeIcon
                size="tiny"
                trigger={
                  <NavDiv>
                    <Button primary data-test-logout-work-station
                      icon="sign-out"
                      size="large"
                      content={'logout'}
                      labelPosition="right"
                    />
                  </NavDiv>
                }
              >
                <Modal.Header>{t('workStation.stopModal.title')}</Modal.Header>
                <Modal.Content>{t('workStation.stopModal.content')}</Modal.Content>
                <Modal.Actions>
                  <RightDivider />
                  <Button data-test-logout-button
                    primary
                    icon="sign-out"
                    labelPosition="right"
                    content={t('workStation.stopModal.stopButton')}
                    onClick={() => this.props.store.performLogout()}
                  />
                </Modal.Actions>
              </Modal>
            </MenuRow>
            {showSecondMenu && (
              <MenuRow data-test-second-menu id="second-menu">
                {/* Hackity hack hack to always show second menu bar */}
                <NavItem title=" " to="/" activePath="/neverusethispath" />
              </MenuRow>
            )}
          </TopMenu>
        </ReCyCleTheme>
      )
    }

    if (this.props.store.isOperator) {
      return (
        <ReCyCleTheme theme={{ ...theme, primaryColor: theme.menuColor }}>
          <TopMenu>
            <MenuRow>
              <MyLogo />
              <NavMenu></NavMenu>
              {this.renderAccountMenu()}
            </MenuRow>
            <MenuRow />
          </TopMenu>
        </ReCyCleTheme>
      )
    }

    if (!this.props.store.isAuthenticated || (currentUser.groupNames === undefined && !currentUser.isSuperuser)) {
      return (
        <ReCyCleTheme theme={{ ...theme, primaryColor: theme.menuColor }}>
          <TopMenu>
            <MenuRow>
              <MyLogo />
            </MenuRow>
            <MenuRow />
          </TopMenu>
        </ReCyCleTheme>
      )
    }

    const logoutModal = (
      <Modal closeIcon open={this.showLogoutModal} onClose={() => (this.showLogoutModal = false)} basic size="small">
        <Modal.Header icon="archive" content={t('user.account.logoutButton')} />
        <Modal.Content>
          <p>{t('user.account.logout.confirm')}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="red" inverted onClick={() => (this.showLogoutModal = false)}>
            <Icon name="remove" /> {t('form.no')}
          </Button>
          <Button
            color="green"
            inverted
            onClick={() => {
              this.props.store.performLogout().then(() => {
                this.showLogoutModal = false
              })
            }}
          >
            <Icon name="checkmark" /> {t('form.yes')}
          </Button>
        </Modal.Actions>
      </Modal>
    )

    let menu = currentUser.isSuperuser ? (
      <TopMenu>
        <MenuRow>
          <MyLogo />
          <NavMenu>
            <NavItem title={t('nav.personnel.users')} to="/personnel/user/overview" activePath="/personnel/user/" />
          </NavMenu>
          {this.integrationStore.length > 0 && this.renderIntegrationsMenu() }
          {this.renderAccountMenu()}
        </MenuRow>
        <MenuRow>
          <Route path="/account" render={this.renderAccount} />
          {/* Hackity hack hack to always show second menu bar */}
          <NavItem title=" " to="/" activePath="/neverusethispath" />
        </MenuRow>
      </TopMenu>
    ) : (
      <TopMenu>
        <MenuRow>
          <MyLogo />
          <NavMenu>
            <NavItem
              title={t('nav.main.operations')}
              to="/operations/production-request/overview"
              activePath="/operations/"
            />
            <NavItem
              title={t('nav.main.demand')}
              to="/demand/sales-order/overview"
              activePath="/demand/"
            />
            <NavItem
              title={t('nav.main.planning')}
              to="/planning/production-request/planner"
              activePath="/planning/"
            />
            <NavItem title={t('nav.main.warehouse')} to="/warehouse/stock" activePath="/warehouse/" />
            <NavItem title={t('nav.main.personnel')} to="/personnel/operator/overview" activePath="/personnel/" />
            <NavItem title={t('nav.main.assets')} to="/assets/article-type/overview" activePath="/assets/" />
          </NavMenu>
          {this.integrationStore.length > 0 && this.renderIntegrationsMenu() }
          {this.renderAccountMenu()}
        </MenuRow>
        <MenuRow>
          <Route path="/operations/" render={this.renderOperations} />
          <Route path="/demand/" render={this.renderSales} />
          <Route path="/planning/" render={this.renderPlanning} />
          <Route path="/warehouse/" render={this.renderWarehouse} />
          <Route path="/personnel/" render={this.renderPersonel} />
          <Route path="/assets/" render={this.renderAssets} />
        </MenuRow>
      </TopMenu>
    )

    return (
      <>
        <ReCyCleTheme theme={{ ...theme, primaryColor: theme.menuColor }}>{menu}</ReCyCleTheme>
        {logoutModal}
      </>
    )
  }
}
