import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { action, observable, computed, reaction } from 'mobx'
import { observer } from 'mobx-react'
import { Form, Button, Popup } from 'semantic-ui-react'
import styled from 'styled-components'
import TextareaAutosize from 'react-textarea-autosize'
import Scrollbars from 'react-custom-scrollbars'
import { theme } from 'styles'
import DEFAULT_AVATAR from 'image/default_avatar.png'
import subscribe from '../../../decorator/subscribe';

// helpers
import { DATETIME_FORMAT } from 'helpers'
// end helpers

// stores
import { User } from 'store/User'
import { Operator } from 'store/Operator'
import { LeaveSlot } from 'store/LeaveSlot'
import { LeaveSlotComment, LeaveSlotCommentStore } from 'store/LeaveSlotComment'
// end stores

const FullCommentsContainer = styled.div`
  width: 350px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
`

const CommentsScrollbars = styled(Scrollbars)`
  flex: 1 1 0;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20px;
    box-shadow: inset 0 -20px 20px -20px rgba(0, 0, 0, 0.1);
    opacity: ${({ scrolled }) => (scrolled ? 1 : 0)};
    transition: opacity 300ms ease;
    z-index: 100;
  }
`

const CommentsContainer = styled.div`
    padding-right 0.75em;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    ${({ empty }) =>
      empty
        ? `
        align-items: center;
        justify-content: center;
        color: rgba(0, 0, 0, 0.2);
        font-size: 1.25rem;
        font-style: italic;
    `
        : `
        justify-content: flex-end;
    `}
`

const NewCommentContainer = styled(Form)`
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 0.75em 0.75em 0.75em 0;
  > textarea {
    min-height: unset !important;
    max-height: unset !important;
    resize: none !important;
    flex: 1 1 0;
    padding: calc(0.78571429em - 0.14285em - 1px) calc(0.78571429em - 1px) !important;
    margin-right: 0.5em !important;
  }
  > button {
    flex: 0 0 auto;
    margin: 0 !important;
  }
`

const SmallPopup = styled(Popup)`
  padding: 0.33em 0.5em !important;
`

const CommentContainer = styled.div`
  margin-top: ${({ first }) => (first ? 0.75 : 0.25)}em;
  margin-left: ${({ own }) => (own ? 1 : 0.33)}em;
  margin-right: ${({ own }) => (own ? 0.33 : 1)}em;
`

const CommentInfoLine = styled.div`
  font-size: 0.75em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.33);
  padding: 0 0.25rem;
  display: flex;
`

const Avatar = styled.img`
  display: inline-block;
  width: 1.25em;
  height: 1.25em;
  object-fit: cover;
  border-radius: 9999px;
  margin: 0.25em 0.33em -0.25em -0.125em;
`

const Filler = styled.div`
  flex: 1 1 0;
`

const CommentMessageContainer = styled.div`
  background-color: ${({ own }) => (own ? theme.tintedPrimaryColor : '#eee')};
  border-radius: 0.5em;
  padding: 0.5em;
  position: relative;
  ${({ last, own }) =>
    last
      ? `
        &:before {
            content: '';
            position: absolute;
            ${own ? 'right' : 'left'}: -0.33em;
            bottom: 0;
            border-bottom: 0.5em solid ${own ? theme.tintedPrimaryColor : '#eee'};
            border-left: 0.33em solid transparent;
            border-right: 0.33em solid transparent;
        }
    `
      : ''}
`

const Nl2Br = ({ text }) =>
  text.split('\n').map((line, i) => (
    <React.Fragment key={i}>
      {i !== 0 && <br />}
      {line}
    </React.Fragment>
  ))

@observer
class Comment extends Component {
  static propTypes = {
    comment: PropTypes.instanceOf(LeaveSlotComment).isRequired,
    first: PropTypes.bool.isRequired,
    last: PropTypes.bool.isRequired,
  }

  @computed get own() {
    const { comment } = this.props
    return (
      comment.user.id === window.viewStore.currentUser.id && comment.operator.id === window.viewStore.currentOperator.id
    )
  }

  render() {
    const { first, last, comment } = this.props

    let avatar = null
    if (!comment.operator.isNew) {
      avatar = <span>
        <Avatar src={comment.operator.avatar || DEFAULT_AVATAR} />
        {comment.operator.fullName}
      </span>
    } else if (!comment.user.isNew) {
      avatar = <span>
        <Avatar src={comment.user.avatar || DEFAULT_AVATAR} />
        {comment.user.fullName}
      </span>
    }
    return (
      <CommentContainer first={first} own={this.own} optimistic={comment.optimistic}>
        {first && (
          <CommentInfoLine>
            {!this.own && avatar}
            <Filler />
            {comment.createdAt.format(DATETIME_FORMAT)}
          </CommentInfoLine>
        )}
        <CommentMessageContainer last={last} own={this.own}>
          <Nl2Br text={comment.message} />
        </CommentMessageContainer>
      </CommentContainer>
    )
  }
}

function group(comment1, comment2) {
  return (
    comment1.user.id === comment2.user.id &&
    comment1.operator.id === comment2.operator.id &&
    // Less than 5 minutes inbetween
    Math.abs(comment1.createdAt.diff(comment2.createdAt, 'm', true)) <= 5
  )
}

@observer
class Comments extends Component {
  static propTypes = {
    comments: PropTypes.instanceOf(LeaveSlotCommentStore).isRequired,
  }

  constructor(...args) {
    super(...args)
    this.onScrollbarsMount = this.onScrollbarsMount.bind(this)
    this.onScrollbarsUpdate = this.onScrollbarsUpdate.bind(this)
    this.onScrollbarsScroll = this.onScrollbarsScroll.bind(this)
    this.renderComment = this.renderComment.bind(this)
  }

  @observable scrolled = false
  scrollbarsNode = null

  onScrollbarsMount(node) {
    this.scrollbarsNode = node
  }

  onScrollbarsUpdate() {
    const { top, clientHeight, scrollHeight } = this.scrollbarsNode.getValues()
    const scrolled = top + clientHeight !== scrollHeight
    if (!this.scrolled && scrolled) {
      this.scrollbarsNode.scrollToBottom()
    } else {
      this.scrolled = scrolled
    }
  }

  onScrollbarsScroll(e) {
    const { scrollTop, clientHeight, scrollHeight } = e.target
    this.scrolled = scrollTop + clientHeight !== scrollHeight
  }

  renderComment(comment, i, comments) {
    return (
      <Comment
        key={comment.cid}
        comment={comment}
        first={i === 0 || !group(comment, comments[i - 1])}
        last={i === comments.length - 1 || !group(comment, comments[i + 1])}
      />
    )
  }

  render() {
    const { comments } = this.props

    return (
      <CommentsScrollbars
        scrolled={this.scrolled}
        innerRef={this.onScrollbarsMount}
        onUpdate={this.onScrollbarsUpdate}
        onScroll={this.onScrollbarsScroll}
      >
        <CommentsContainer data-test-comments empty={comments.length === 0}>
          {comments.length === 0 ? t('leaveSlot.edit.comments.empty') : comments.map(this.renderComment)}
        </CommentsContainer>
      </CommentsScrollbars>
    )
  }
}

@observer
class NewComment extends Component {
  static propTypes = {
    leaveSlot: PropTypes.instanceOf(LeaveSlot).isRequired,
  }

  constructor(...args) {
    super(...args)
    this.onChangeMessage = this.onChangeMessage.bind(this)
    this.onKeyPressMessage = this.onKeyPressMessage.bind(this)
    this.onSend = this.onSend.bind(this)
  }

  @observable message = ''

  @action onChangeMessage(e) {
    this.message = e.target.value
  }

  @action onKeyPressMessage(e) {
    if (e.key === 'Enter' && e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) {
      e.preventDefault()
      this.onSend()
    }
  }

  @action onSend() {
    const { leaveSlot } = this.props
    return leaveSlot.comments.wrapPendingRequestCount(
      new LeaveSlotComment(
        {
          message: this.message,
          leaveSlot: leaveSlot.toJS(),
        },
        {
          relations: ['leaveSlot'],
        }
      )
        .save({ data: { leave_slot: leaveSlot.id } })
        .then(() => (this.message = ''))
    )
  }

  render() {
    const { leaveSlot } = this.props
    return (
      <NewCommentContainer data-test-new-comment>
        <TextareaAutosize
          minRows={1}
          maxRows={5}
          value={this.message}
          onChange={this.onChangeMessage}
          onKeyDown={this.onKeyPressMessage}
        />
        <SmallPopup
          trigger={<Button primary icon="send" loading={leaveSlot.comments.isLoading} onClick={this.onSend} />}
          content={'Shift+Enter'}
        />
      </NewCommentContainer>
    )
  }
}

@observer
@subscribe
export default class LeaveSlotViewComments extends Component {
  static propTypes = {
    leaveSlot: PropTypes.instanceOf(LeaveSlot).isRequired,
    user: PropTypes.instanceOf(User),
    operator: PropTypes.instanceOf(Operator),
  }

  constructor(...args) {
    super(...args)
    this.onCommentUpdated = this.onCommentUpdated.bind(this)
  }

  componentDidMount() {
    this.leaveSlotReaction = reaction(
      () => [
        this.props.leaveSlot.id,
        (this.props.user || this.props.leaveSlot.user || { id: null }).id,
        (this.props.operator || this.props.leaveSlot.operator || { id: null }).id,
      ],
      ([leave_slot, user, operator]) => {
        if (leave_slot) {
          this.subscribe(
            {
              type: 'leave_slot_comment_updated',
              leave_slot,
              user,
              operator,
            },
            this.onCommentUpdated
          )
        }
      },
      { fireImmediately: true }
    )
  }

  componentWillUnmount() {
    this.leaveSlotReaction()
  }

  onCommentUpdated({ data }) {
    const { leaveSlot } = this.props

    const comment = leaveSlot.comments.get(data.id)
    if (comment) {
      comment.parse(data)
    } else {
      leaveSlot.comments.add(data)
    }
  }

  render() {
    const { leaveSlot } = this.props

    return (
      <FullCommentsContainer>
        <Comments comments={leaveSlot.comments} />
        <NewComment leaveSlot={leaveSlot} />
      </FullCommentsContainer>
    )
  }
}
