import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ArticleType } from './ArticleType.js'
import { StorageLocation } from './StorageLocation'
import { Warehouse } from './Warehouse.js'
import Decimal from 'decimal.js'

export class ArticleTypeWarehouse extends Model {
  static backendResourceName = 'article_type_warehouse'
  static omitField = ['stock', 'totalUnallocated', 'futureUnallocated', 'unallocatedDeficit']

  @observable id = null
  @observable plannedStockIn = 0
  @observable projectedStock = 0

  // aggregated over all storage location stock positions
  @observable warehouseStock = Decimal(0)
  @observable stock = Decimal(0)
  @observable unallocatedStock = Decimal(0)

  /**
   * Current unallocated stock + future unallocated stock
   * @type {*}
   */
  @observable totalUnallocated = Decimal(0)
  @observable futureUnallocated = Decimal(0)
  @observable unallocatedDeficit = 0 // Marked for deletion.

  @observable expectedStock = Decimal(0)
  @observable unallocatedExpectedStock = Decimal(0)

  relations() {
    return {
      articleType: ArticleType,
      defaultStorageLocation: StorageLocation,
      warehouse: Warehouse,
    }
  }

  casts() {
    return {
      unallocatedStock: Casts.decimal,
      totalUnallocated: Casts.decimal,
      futureUnallocated: Casts.decimal,
      unallocatedDeficit: Casts.decimal,
      expectedStock: Casts.decimal,
      unallocatedExpectedStock: Casts.decimal,
    }
  }
}

export class ArticleTypeWarehouseStore extends Store {
  Model = ArticleTypeWarehouse
  static backendResourceName = 'article_type_warehouse'
}
